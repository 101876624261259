import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Row,
    Col,
    TabContent,
    TabPane,
    Button,
} from "reactstrap";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import {CreditCard} from "@material-ui/icons";
import {ZoomIn} from '@material-ui/icons';
import {MonetizationOn} from '@material-ui/icons';
import {AccountBalance} from '@material-ui/icons';
import {Info} from '@material-ui/icons';


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import classNames from "classnames";
const useStyles = makeStyles(aboutUsStyle);
// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function Participa() {
    const classes = useStyles();
    const [iconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <div>
            <Parallax image={require("../../assets/img/sections/bucaramanga.jpg")} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto,
                                classes.textCenter
                            )}
                        >
                            <h1 className={classes.title}>Participa</h1>
                            <h4>

                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{ paddingTop: '50px', paddingBottom: '30px' }}>
                    <h4>Participa</h4>
                </div>
            </div>
            <div className={classNames(classes.main, classes.mainRaised)}>


                <Row className="justify-content-center">
                    <Col md="10">
                        <p className="text-justify" style={{ fontSize: "14px", color: "#68696F", fontFamily: "serif", marginTop: '50px' }}>
                            El Menú Participa es una nueva categoría del menú destacado que deben crear las entidades y organismos obligados en
                            el encabezado principal de su sitio web. Las autoridades deben publicar en el Menú Participa los contenidos de información
                            sobre los espacios, mecanismos y acciones que permiten la participación ciudadana en el ciclo de la gestión pública,
                            esto con el propósito de dinamizar la vinculación de la ciudadanía en las decisiones y acciones públicas durante el
                            diagnóstico, formulación, implementación, evaluación y seguimiento a la gestión institucional a través de su sitio
                            web institucional. Contiene la información sobre las posibilidades de interacción e intervención de los ciudadanos,
                            usuarios y grupos de interés en las fases de formulación, ejecución, control y evaluación del ciclo de la gestión
                            pública definidas en la normatividad general y en las disposiciones específicas para cada entidad, o que son diseñados
                            proactivamente para facilitar el ejercicio de este derecho.* La estructura del Menú Participa debe contener seis secciones,
                            así:
                            <br></br>
                            <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                <small style={{ color: 'blue' }}>*  Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información
                                    en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de:
                                    <a style={{ color: 'blue' }} href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        https://www.funcionpublica.gov.co/documents</a></small>
                            </a>
                        </p>
                        <TabContent
                            className="text-center"
                            activeTab={"iconTabs" + iconTabs}
                        >
                            <TabPane tabId="iconTabs1">
                                <Row>
                                    <Col md="2">
                                        <div className="info info-hover">
                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas';
                                                }}>
                                                <div className="icon icon-secondary">
                                                    <AccountBalance />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Diagnóstico e Identificación <br /> de problemas</b>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="info info-hover">

                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/planeacion-y-presupuesto-participativo';
                                                }}>
                                                <div className="icon icon-secondary">
                                                    <CreditCard />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Planeación y presupuesto <br /> participativo</b>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="info info-hover">

                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/consulta-ciudadana';
                                                }}>
                                                <div className="icon icon-secondary">
                                                    <ZoomIn />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Consulta ciudadana</b>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="info info-hover">

                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/rendicion-de-cuentas';
                                                }}>
                                                <div className="icon icon-secondary">
                                                    <MonetizationOn />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Rendición de Cuentas</b>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="info info-hover">

                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/control-social';
                                                }}>
                                                <div className="icon icon-secondary">
                                                <Info />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Control Social</b>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="info info-hover">

                                            <Button
                                                className="btn-round btn-sm btn-link btn-lg"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/page/colaboracion-e-innovacion-abierta';
                                                }}>
                                                <div className="icon icon-secondary">
                                                    <Info />
                                                </div>
                                                <b style={{ fontSize: "9px", color: "gray" }}>Colaboración e innovación abierta</b>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>

            </div>
        </div>

    );

}

export default Participa;