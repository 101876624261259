/*eslint-disable*/
import React from "react";

// core components

function Logos() {
  return (
    <>
      <div className="card" style={{marginBottom:'0px'}}>
        <b>Entidades, agremiaciones y asociaciones</b>
        <div className="card-body">
          <div className="row text-center">
            <div className="col-md-2 col-sm-2" 
            //   style={{marginTop:'15px', marginLeft:'3em'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://minjusticia.gov.co/">
                <img
                  alt="Logo del Ministerio de Justicia y del Derecho de Colombia"
                  className="log"
                  src={require("assets/img/sections/minJusticia2.png")}
                  //   style={{height:'85%'}}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2" 
              //   style={{marginLeft:'1em',marginTop:'4px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.supernotariado.gov.co/">
                <img
                  alt="Logo de la Superintendencia de Notariado y Registro"
                  className='log'
                  src={require("assets/img/sections/SNR.png")}
                  //   style={{ height: "92%" }}
                ></img>
              </a>
            </div>
            <div className="col-md-1 col-sm-1" 
              //   style={{marginLeft:'1em',marginTop:'4px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.procuraduria.gov.co/portal/">
                <img
                  alt="Logo de Procuraduria General de la Nacion"
                  className='log'
                  src={require("assets/img/sections/procuraduria.jpg")}
                  //   style={{ height: "92%" }}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2" 
              //   style={{marginLeft:'1em',marginTop:'4px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.vur.gov.co/">
                <img
                  alt="Logo VUR"
                  className='log'
                  src={require("assets/img/sections/vur.png")}
                  //   style={{ height: "92%" }}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-1" 
              //   style={{marginLeft:'1em',marginTop:'4px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.suin-juriscol.gov.co/">
                <img
                  alt="Logo SUIN Juriscol"
                  className='log'
                  src={require("assets/img/sections/suin.png")}
                  //   style={{ height: "92%" }}
                ></img>
              </a>
            </div>
            <div className="col-md-2 col-sm-2" 
              //   style={{marginLeft:'1em',marginTop:'4px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.registraduria.gov.co/">
                <img
                  alt="Logo Registraduria Nacional del Estado CIvil"
                  className='log'
                  src={require("assets/img/sections/registraduria.png")}
                  //   style={{ height: "92%" }}
                ></img>
              </a>
            </div>
          </div >
        </div>
      </div>
      <div className="card" style={{marginBottom:'0px'}}>
        <b>Directorio de agremiaciones, asociaciones y otros grupos de interés</b>
        <div className="card-body">
          <div className="row text-center">
            <div className="col-md-2 col-sm-2" 
            //   style={{marginLeft:'-1em',marginTop:'5px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.ucnc.com.co/sitio/">
                <img
                  alt="Logo de la Unión Colegiada del Notariado Colombiano"
                  className='log'
                  src={require("assets/img/sections/UnionColegiala.jpg")}
                  //   style={{ height: "95%" }}
                ></img>
              </a>
            </div>                  
            <div className="col-md-1 col-sm-1" 
            //   style={{marginLeft:'5em',marginTop:'37px'}}
            >
              <a  target="_blank" rel="noopener noreferrer" href="https://www.gov.co/home/">
                <img
                  alt="Logo de la página web del Gobierno Colombiano"
                  className="logos2"
                  src={require("assets/img/sections/logo-gov-co.png")}
                  //   style={{ width: "150%" }}
                ></img>
              </a>
            </div>
          </div >
        </div>
      </div>
    </>
  );
}
export default Logos;
